<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Mã quy trình"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        :rules="{ required: true }"
        name="maWorkflow"
      >
        <b-form-input
          id="input-1"
          ref="maWorkflow"
          v-model="data.code"
          v-trim-input
          :class="{required: errors.length > 0}"
          type="text"
          :autofocus="data.id ? false : true"
          placeholder="Nhập mã workflow"
          @input="e => dataForm.code = data.code"
        />
        <span class="text-danger">
          {{ errors[0] }}
        </span>
        <span class="text-danger">
          {{ errorTrungMa }}
        </span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Tên luồng xử lý"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="data.description"
        v-trim-input
        type="text"
        placeholder="Nhập vào tên quy trình"
        required
        @input="e => dataForm.description = data.description"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Thứ tự"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model.number="data.order"
        type="number"
        placeholder="Nhập số thứ tự"
        required
        @change="notAllowMin"
        @input="e => dataForm.order = data.order"
      />
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Đơn vị"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="donVi"
        :rules="{ required: true }"
        name="donVi"
      >
        <treeselect
          id="linhVuc"
          ref="donVi"
          v-model="data.donViId"
          v-format-v-select
          :options="dvCbx"
          placeholder="Chọn đơn vị"
          :normalizer="normalizer"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :match-keys="['label', 'name']"
          @input="e => dataForm.donViId = data.donViId"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Trạng thái"
      label-for="input-1"
    >
      <b-form-checkbox
        id="checkbox-1"
        v-model="dataForm.status"
        name="checkbox-1"
        switch
      />
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { removeDiacritical } from '@/utils/index'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
      dvCbx: [],
      data: {
        code: null,
        description: null,
        status: true,
        order: null,
        activeVersion: 0,
        donViId: null,
      },
    }
  },
  created() {
    try {
      this.getDataDonVi()
    } finally {
      setTimeout(() => {
        this.data = this.dataForm
      }, 1000)
    }
  },
  methods: {
    notAllowMin() {
      if (this.dataForm.order < 0) {
        this.dataForm.order = 0
      }
    },
    getDataDonVi() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dvCbx = this.listToTree(formatDonVi)
        }
      })
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          name: removeDiacritical(node.tenDonVi),
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
        name: removeDiacritical(node.tenDonVi),
      }
    },
  },
}
</script>

<template>
  <section>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="card-body">
        <div class="d-sm-flex justify-content-start">
          <button
            class="compact ui button text-height"
            @click="getDataNewLuongXuLy"
          >
            <refresh-cw-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            /> Tải lại
          </button>
          <button
            class="compact ui btn-primary button text-height"
            @click="addCommom"
          >
            <plus-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            /> Thêm mới
          </button>
          <button
            class="compact ui btn-primary button text-height"
            :class="selectedNumber < 1 ? 'disabled' : ''"
            @click="deleteCommom"
          >
            <trash-2-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            />
            Xoá
          </button>
        </div>
        <good-table
          ref="vgTable"
          class="mt-1"
          :columns="columns"
          :rows="rows"
          :total="total"
          :is-loading="isLoading"
          @column-filter="columnFilter"
          @selected-item="selectedItem"
          @page-change="pageChange"
        >
          <div
            slot="custom-row"
            slot-scope="{ props }"
          >
            <span v-if="props.column.field == 'code'">
              <a
                :href="`${ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME + ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY}/xem/${props.row.id}?donViId=${props.row.donViId}`"
              >{{ props.row.code }}</a>
            </span>
            <span v-else-if="props.column.field == 'activeVersion'">
              Phiên bản {{ props.row.activeVersion }}
            </span>
            <span v-else-if="props.column.field != 'status'">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </div>
          <div
            slot="actions"
            slot-scope="{ props }"
          >
            <edit-icon
              v-b-tooltip.hover.v-secondary
              title="Cập nhật"
              size="16"
              class="custom-class cursor-pointer mr-1"
              @click="updateCommom(props.row)"
            />
            <trash-2-icon
              v-b-tooltip.hover.v-secondary
              title="Xóa"
              size="16"
              class="custom-class cursor-pointer"
              @click="deleteCommomTable(props.row)"
            />
          </div>
        </good-table>
      </div>
      <common-modal
        ref="commonModal"
        :title="title"
        :size="size"
        @handle-ok="handleOk"
        @handle-focus="handleFocusError"
      >
        <component
          :is="componentName === 'XoaForm' ? '' : componentName"
          ref="componentName"
          :data-form="dataForm"
        />
        <div
          v-if="isShow"
          slot="footer"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$refs.commonModal.checkValidate()"
          >
            Lưu
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="openModal"
          >
            Đóng
          </b-button>
        </div>
      </common-modal>
      <common-modal
        ref="commomnModal"
        title="Xác nhận"
        size="sm"
      >
        Bạn có xác nhận đóng quá trình nhập liệu này không?
        <div
          v-if="isShowFooter"
          slot="footer"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$refs.commonModal.hideModal(),$refs.commomnModal.hideModal()"
          >
            Đồng ý
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="$refs.commomnModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </common-modal>
    </b-card>
  </section>
</template>

<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { ROUTE_NAME } from '@/modules/quan-ly-quy-trinh/constants/constants'
import {
  RefreshCwIcon,
  XIcon,
  PlusIcon,
  EditIcon,
  Trash2Icon,
} from 'vue-feather-icons'
import GoodTable from '@/components/GoodTable.vue'
import LuongXuLyForm from '@/modules/quan-ly-quy-trinh/components/form/LuongXuLyForm.vue'
import addCssSemantic from '@/mixins/mixins'
import CommonModal from '@/modules/mot-cua/components/modal/CommonModal.vue'
import { compareObjects, removeDiacritical } from '@/utils/index'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DanhSachHoSo',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    RefreshCwIcon,
    PlusIcon,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    GoodTable,
    BCard,
    LuongXuLyForm,
    CommonModal,
    EditIcon,
    Trash2Icon,
    XIcon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      ROUTE_NAME,
      title: 'Thêm mới luồng xử lý',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã luồng xử lý',
          width: '180px',
          field: 'code',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên luồng xử lý',
          field: 'description',
          width: 'auto',
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Phiên bản',
          field: 'activeVersion',
          thClass: 'text-center',
          width: '150px',
          tdClass: 'text-left',
        },
        {
          label: 'Số thứ tự',
          field: 'order',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Ngày tạo',
          field: 'createdOnDate',
          type: 'date',
          width: '180px',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: [],
      bcCbx: [],
      isShow: false,
      isShowFooter: false,
      dvCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        page: 1,
        size: 10,
        code: null,
        // status: null,
        // activeVersion: null,
        donviId: null,
        fields: null,
        fullTextSearch: null,
        id: null,
        listId: [],
        sort: '+Id',
      },
      dataForm: {
        code: null,
        description: null,
        status: true,
        order: 0,
        activeVersion: 0,
        donViId: null,
      },
      isLoading: false,
      size: null,
      beginObject: {},
    }
  },
  created() {
    this.getDataLuongXuLy()
  },
  methods: {
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới luồng xử lý'
      this.componentName = 'LuongXuLyForm'
      this.size = 'lg'
      this.dataForm = {
        code: null,
        description: null,
        status: true,
        order: 0,
        activeVersion: 0,
        donViId: null,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataLuongXuLy() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, this.payload, false).then(res => {
          if (res.data?.code === 200) {
            this.rows = res.data.data.content
            this.total = res.data.data.totalElements
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    getDataNewLuongXuLy() {
      this.payload = {
        page: 1,
        size: 10,
        code: null,
        // status: null,
        // activeVersion: null,
        donviId: null,
        fields: null,
        fullTextSearch: null,
        id: null,
        listId: [],
        sort: '+Id',
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.getDataLuongXuLy()
    },
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commomnModal.hideModal()
      } else {
        this.isShowFooter = true
        this.$refs.commomnModal.showModal()
      }
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        this.isShow = false
        let url = `${END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.XOA}?`
        this.selectedItems.forEach(item => {
          url += `listId=${item.id}&`
        })
        this.$axios.delete(url, null, false).then(res => {
          if (res.data?.code === 200) {
            if (this.selectedItems.length >= this.rows.length && this.payload.page > 1) {
              this.payload.page -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.page)
            }
            this.$toast.success(res.data.message)
            this.getDataLuongXuLy()
            this.$refs.commonModal.hideModal()
          } else {
            this.$toast.error(res.data.message)
          }
        })
      } else if (this.componentName === 'LuongXuLyForm') {
        if (this.dataForm?.id) {
          try {
            this.$axios.put(`${END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.SUA}/${this.dataForm?.id}`, this.dataForm, false).then(res => {
              if (res.data?.code === 200) {
                this.getDataLuongXuLy()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
              } else if (res.data?.code === 400) {
                this.$refs.componentName.errorTrungMa = res.data.message
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        } else {
          try {
            this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.THEM, this.dataForm, false).then(res => {
              if (res.data?.code === 200) {
                this.getDataLuongXuLy()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
              } else if (res.data?.code === 400) {
                this.$refs.componentName.errorTrungMa = res.data.message
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật luồng xử lý'
      this.componentName = 'LuongXuLyForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        code: data.code,
        description: data.description,
        status: data.status,
        order: data.order,
        activeVersion: data.activeVersion,
        donViId: data.donViId,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        page: data.pageNumber,
        size: data.pageSize,
      }
      this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, this.payload, false).then(res => {
        if (res.data?.code === 200) {
          this.rows = res.data.data.content
          this.total = res.data.data.totalElements
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        code: data.code ? data.code.replace(/\s+/g, ' ').trim() : '',
        donviId: null,
        id: null,
        listId: [],
        page: 1,
        size: this.payload.size,
      }
      this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, this.payload, false).then(res => {
        if (res.data?.code === 200) {
          this.rows = res.data.data.content
          this.total = res.data.data.totalElements
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['donVi'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }

</style>

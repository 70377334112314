<template>
  <danh-sach />
</template>

<script>
import DanhSach from '@/modules/quan-ly-quy-trinh/components/pages/QuanLyLuongXuLy/DanhSach.vue'

export default {
  name: 'TrangDanhSach',
  components: {
    DanhSach,
  },
}
</script>

<style scoped></style>
